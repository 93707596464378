.about__title {
  color: #ffffff;
  font-family: "Orbitron", "Roboto", "Arial";
}

.about__text {
  color: #ffffff;
  font-family: "Orbitron", "Roboto", "Arial";
  font-size: 18px;
  font-weight: 600;
}

.about__text {
  opacity: 0; /* Initially hidden */
  animation: jumpIn 1.2s ease forwards;
}

@keyframes jumpIn {
  0% {
    transform: translateY(50px); /* Start below */
    opacity: 0;
  }
  60% {
    transform: translateY(-20px); /* Bounce up */
    opacity: 1;
  }
  100% {
    transform: translateY(0); /* Settle in place */
    opacity: 1;
  }
}


.about__image {
  height: 320px;
  width: 320px;
  background-color: transparent;
  border-radius: 30%;
  border: 1px black;
  padding: 24px 40px 0 0;
}

.about__image {
  animation: tilt 6s ease-in-out infinite;
}

@keyframes tilt {
  0% {
    transform: rotate(20deg);  /* Tilt to one side */
  }
  50% {
    transform: rotate(-20deg);  /* Tilt to the opposite side */
  }
  100% {
    transform: rotate(20deg);  /* Back to the original tilt */
  }
}

.about__container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin: auto;
}

.about__info {
  display: flex;
  flex-direction: column;
  width: 520px;
  transition: transform 0.3s ease-in-out;
  padding: 0 20px 0 20px;
}

.about__info:hover {
  transform: scale(1.05); /* Scale the entire text block slightly */
  cursor: zoom-in;
  background-color: black;
  border:none;

}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .about__container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    margin: auto;
  }

  .about__info {
    padding: 0;
    width: 480px;
  }

  .about__image {
    height: 220px;
    width: 220px;
    padding: 110px 40px 0 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .about__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .about__image {
    padding: 0;
    height: 180px;
    width: 180px;
  }

  .about__info {
    padding: 0;
    width: 90%;
  }
  .about__text {
    color: #ffffff;
    font-family: "Orbitron", "Roboto", "Arial";
    font-size: 14px;
    font-weight: 500;
  }
}
