.App {
  text-align: center;
  background-color: #000000;
}

.App__background-wrapper {
  background-image: url("../../images/space.jpg");
  width: 100%;
  height: 576px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .App__background-wrapper {
    height: 100%;
  }
}
