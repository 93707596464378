@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.header {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__left {
  padding-left: 80px;
}

.header__right {
  padding-right: 80px;
}

.header__title {
  font-family: "Orbitron", "Roboto", "Arial";
  color: #ffffff;
}

.header__text {
  font-family: "Orbitron", "Roboto", "Arial";
  color: #ffffff;
}

.header__contact-container {
  position: fixed;
  bottom: 60px;
  right: 30px;
  transition: all 0.3s ease-in-out;
}

.header__contact-rocket-button {
  width: 60px;
  height: 60px;
  background-color: #377b98;
  border: none;
  cursor: pointer;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
}

.header__contact-rocket-button:hover {
  opacity: 50%;
}

.header__contact-rocket-button.pulse {
  animation: pulse 2s infinite;
}

.header__contact-text {
  font-family: "Roboto" "Times New Roman", Times, serif;
  font-size: 8px;
  margin: 0;
  color: #000000;
}

.header__contact-rocket-icon {
  width: 30px;
  height: 30px;
  color: #000000;
}

.header__button {
  font-family: "Orbitron", "Roboto", "Arial";
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-color: transparent;
  border: none;
  padding-left: 60px;
}

.header__button:hover {
  cursor: pointer;
  opacity: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header__left {
    padding-left: 40px;
  }

  .header__right {
    padding-right: 40px;
  }

  .header__button {
    padding-left: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .header {
    display: flex;
    flex-direction: column;
  }
  .header__left {
    padding-left: 0px;
    padding-right: 0px;
    width: 320px;
    height: 74px;
  }

  .header__right {
    width: 320px;
    display: flex;
    justify-content: center;
    padding-right: 0px;
  }

  .header__button {
    padding-left: 0px;
    padding-right: 0px;
  }

  .header__button-main {
    padding: 0 30px 0 30px;
  }
}
