.main {
  width: 100%;
}

.main__text {
  color: #ffffff;
  margin-top: 40px;
}

.main__image {
  width: 340px;
  height: 200px;
}

.main__image-us {
  border: 1px solid #ffffff;
}

.main__button {
  padding: 30px 0 0 0;
  border: none;
  margin: 0;
  background-color: #000000;
}

.main__button:hover {
  opacity: 50%;
}

.main__wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.main__card {
  display: flex;
  padding: 0 80px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.main__card-right {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.main__info {
  padding: 0 40px;
}

.main__info-title {
  font-family: "Roboto", "Times New Roman", Times, serif;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.main__info-description {
  font-family: "Roboto", "Times New Roman", Times, serif;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.main__description-link {
  font-family: "Roboto", "Times New Roman", Times, serif;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  margin-top: 0px;
}

.main__description-link:hover {
  opacity: 70%;
}

.visible {
  opacity: 1;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .main__card {
    padding: 40px 40px 0;
    align-items: center;
  }

  .main__image {
    width: 240px;
    height: 150px;
    display: flex;
    align-self: center;
  }

  .main__info-title {
    margin-top: 0;
  }

  .main__wrapper {
    padding: 0 0 30px 0;
  }

  .main__text {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main__card {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .main__card-right {
    display: flex;
    flex-direction: column-reverse;
  }

  .main__image {
    width: 310px;
  }

  .main__info {
    padding: 0;
  }

  .main__wrapper {
    padding: 0 0 30px 0;
  }

  .main__info-description {
    font-size: 13px;
  }
}
